import {fetch} from "@/utils/request.js";

export default {
  data() {
    return {
      detailMixinOptions: {
        url: "",
        idKey: "oid"
      },
      detailQuery: {},
      detail: {},
      hasDetail: false
    }
  },
  methods: {
    findDetailForDetail(res) {
      return res.data
    },
    getDetailQuery(query) {
      return query
    },
    afterGetDetail() {

    },
    getDetail(oid) {
      let url = window.mock && this.detailMixinOptions.mockUrl ? this.detailMixinOptions.mockUrl : this.detailMixinOptions.url
      fetch("post", url, {
        ...this.getDetailQuery(this.detailQuery),
        [this.detailMixinOptions.idKey]: oid
      }).then(res => {
        if (res.status === 0 || window.mock) {
          console.log('1111111',res);
          this.hasDetail = true
          this.detail = this.findDetailForDetail(res)
          this.afterGetDetail()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}