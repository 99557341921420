<template>
  <div class="app-main-content">
    <card title="任务项" class="left">
      <a-tree :tree-data="treeData" show-icon default-expand-all :default-selected-keys="['0']" @select="selectTree">
        <template slot="switcherIcon">
          <i class="iconfont icon-xiangxia" style="font-size: 12px;" />
        </template>
      </a-tree>
    </card>
    <card body-class="overflow-hidden page-card-body" :is-head="false" style="flex: 1;overflow-x: auto;">
      <template #tools>
        <div class="right">
          <div class="item">
            <a-checkbox @change="changeSelfFlag">我创建的任务</a-checkbox>
          </div>
          <div class="item">
            <span class="label">排序</span>
            <a-select v-model="listQuery.orderby" @change="getList()" placeholder="请选择" style="width: 160px">
              <a-select-option :value="item.value" v-for="item in orderbyList" :key="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input class="search-input" v-model="listQuery.keywords" style="width: 180px"
                     placeholder="请输入标题或者编码">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-button type="primary" :disabled="selectedRowKeys.length === 0" @click="openBatchChangeModal">批量调整
            </a-button>
          </div>
          <div class="item">
            <a-button @click="isFilter = !isFilter">筛选
              <a-icon class="filter" :class="{rotate:isFilter}" type="double-right" />
            </a-button>
          </div>
          <div class="item">
            <a-button class="search" @click="getList">查询</a-button>
          </div>
        </div>
      </template>

      <div class="filter-container" v-show="!isFilter">
        <div class="title">筛选条件</div>
        <div class="content">
          <div class="item">
            <div class="label">计划开始</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.schedule_start_date" />
          </div>
          <div class="item">
            <div class="label">计划结束</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.schedule_end_date" />
          </div>
          <div class="item">
            <div class="label">实际开始</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.actual_start_date" />
          </div>
          <div class="item">
            <div class="label">实际结束</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.actual_end_date" />
          </div>
          <div class="item">
            <div class="label">创建时间</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD"
                            v-model="listQuery.create_time" />
          </div>
          <div class="item">
            <div class="label">状态</div>
            <a-select v-model="listQuery.issue_status" @change="search">
              <a-select-option value="">全部</a-select-option>
              <a-select-option v-for="item in cat.issue_bstatus" :key="item.oid" :value="item.cat_code">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label">优先级</div>
            <a-select v-model="listQuery.issue_level" @change="search">
              <a-select-option v-for="item in [{cat_code:'',cat_name:'全部'},...cat.issues_level]" :key="item.cat_code"
                               :value="item.cat_code">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label">标签</div>
            <a-select style="width: 200px;height: 32px;overflow: hidden;" v-model="listQuery.issue_tags"
                      placeholder="请选择标签" mode="multiple" @change="search" :maxTagCount="1" allowClear>
              <a-select-option v-for="item in cat.issue_tags" :key="item.cat_code" :value="item.cat_name">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label">负责人</div>
            <a-select
                placeholder="请选择负责人"
                mode="multiple"
                :maxTagCount="1"
                v-model="listQuery.issue_owners" @change="search">
              <a-select-option v-for="item in searchOptions.accountList" :key="item.oid" :value="item.oid">
                {{ item.title ? item.name + '-' + item.title : item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label" style="width: 64px;">协同人</div>
            <a-select
                placeholder="请选择协同人"
                mode="multiple"
                :maxTagCount="1"
                v-model="listQuery.issue_assists" @change="search">
              <a-select-option v-for="item in searchOptions.accountList" :key="item.oid" :value="item.oid">
                {{ item.title ? item.name + '-' + item.title : item.name }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>

      <div class="condition">
        <span>共 {{ page.total }} 条符合条件的结果</span>
        <!-- <a-input class="condition_input" placeholder="负责人 包含“自己" allow-clear />
        <div class="condition_clear">清空条件</div> -->
      </div>
      <div class="body-flex">
        <div class="body-left-table" style="display: flex">
          <vxe-table
              ref="xTable"
              :data="list"
              :row-config="{
                  keyField: 'oid',
                  isHover: true,
                  isCurrent: true
                }"
              :checkbox-config="{
                  checkStrictly: true
                }"
              :tree-config="{
                  reserve: true,
                  transform: true,
                  expandAll: true,
                  parentField: 'parent_oid',
                  rowField: 'oid',
                  iconOpen: 'iconfont icon-xiangxia expand-icon',
                  iconClose: 'iconfont icon-xiangxia expand-icon open'
                }"
              :column-config="{resizable: true}"
          >
            <vxe-column type="checkbox" width="50">
              <template #header>
                <a-checkbox
                    @change="toggleAllCheck()"
                    :indeterminate="check.all_indeterminate"
                    :checked="check.all_indeterminate ? undefined : check.all_checked"
                />
              </template>
              <template #checkbox="{ row }">
                <a-checkbox
                    @change="toggleCheck(row)"
                    :checked="selectedRowKeys.includes(row.oid)"
                />
              </template>
            </vxe-column>
            <vxe-column title="ID" field="seqid" width="70px">
            </vxe-column>
            <vxe-column title="任务编码" :resizable="true" width="150px">
              <template v-slot="{row}">
                <a-tooltip>
                  <template slot="title">{{ row.issue_code || '暂无' }}</template>
                  <edp-input
                      style="--width: 100%"
                      v-model="row.issue_code"
                      :db="dbs.prjIssue"
                      field-name="issue_code"
                      :oid="row.oid"
                      :prj-oid="row.prjoid"
                      @change="getList"
                  >
                  </edp-input>
                </a-tooltip>
              </template>
            </vxe-column>
            <vxe-column title="标题" :resizable="true" :width="(maxDeep * 25 + 250) + 'px'" tree-node>
              <template v-slot="{row}">
                <div class="edp-title">
                  <template v-if="row.issue_type === 'R'">
                    <!--                  需求-->
                    <img v-if="row.is_later" class="icon" :src="$toUrl('req_later.png')">
                    <img v-else-if="row.issue_status === '0'" class="icon" :src="$toUrl('req.png')">
                    <img v-else-if="row.issue_status === '1'" class="icon" :src="$toUrl('req_progress.png')">
                    <img v-else-if="row.issue_status === '100'" class="icon" :src="$toUrl('req_complete.png')">
                  </template>
                  <template v-if="row.issue_type === 'T'">
                    <!--                  任务-->
                    <img class="icon" v-if="row.is_later === '1' || row.issue_status === '10'"
                         :src="$toUrl('task_red.png')" alt="">
                    <img class="icon" v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                    <img class="icon" v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                    <img class="icon" v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
                  </template>
                  <template v-if="row.issue_type === 'M'">
                    <!--                  里程碑-->
                    <img class="icon" :src="`${appsettings.imgPrefix}milepost_blue.png`">
                  </template>
                  <template v-if="row.issue_type === 'F'">父节点</template>
                  <template v-if="row.issue_type === 'Q'">缺陷</template>
                  <a-tooltip>
                    <template slot="title">{{ row.issue_name }}</template>
                    <span
                        style="margin-left: 5px;margin-right: 15px;vertical-align: middle;cursor: pointer;min-width: 185px"
                        @click="openTaskDetailModal(row)">{{
                        row.issue_name
                      }}</span>
                  </a-tooltip>
                  <a-tooltip style="margin-left: 5px;">
                    <template slot="title">设置前置任务项</template>
                    <i class="iconfont icon-guanlian hidden" @click="openRelevancyWorkModal(row)"></i>
                  </a-tooltip>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="前置任务" width="100px">
              <template v-slot="{row}">
                <a-tooltip>
                  <template slot="title">{{ row.pretasks || '暂无' }}</template>
                  <edp-input
                      style="--width: 100%"
                      v-model="row.pretasks"
                      :db="dbs.prjIssue"
                      field-name="pretasks"
                      :oid="row.oid"
                      :prj-oid="row.prjoid"
                  >
                  </edp-input>
                </a-tooltip>
              </template>
            </vxe-column>
            <vxe-column title="项目名称" field="project_name" width="200px"></vxe-column>
            <vxe-column title="阶段名称" field="phase_name" width="140px"></vxe-column>
            <vxe-column title="类型" field="issue_type_name" width="90px"></vxe-column>
            <vxe-column title="优先级" width="85px">
              <template v-slot="{row}">
                <div v-if="row.issue_level_name" class="edp-tag "
                     :class="{
                      green: row.issue_level === '100',
                      yellow: row.issue_level === '300',
                      blue: row.issue_level === '200',
                      red: row.issue_level === '400',
                    }">
                  {{ row.issue_level_name }}
                </div>
              </template>
            </vxe-column>
            <vxe-column title="进度" width="100px">
              <template v-slot="{row}">
                <edp-input
                    v-if="['T', 'M'].includes(row.issue_type)"
                    @click.native.stop
                    size="mini"
                    number
                    style="--width: 60px"
                    :db="dbs.prjIssue"
                    field-name="complete_percent"
                    v-model="row.complete_percent"
                    :oid="row.oid"
                    :prj-oid="row.prjoid"
                    :precision="0"
                >
                  <span>{{ row.complete_percent ? `${row.complete_percent}%` : `` }}</span>
                </edp-input>
                <span v-else>{{ row.complete_percent ? `${row.complete_percent}%` : `` }}</span>
              </template>
            </vxe-column>
            <vxe-column title="状态" width="85px" field="issue_status">
              <template v-slot="{row}">
                <div
                    class="edp-status"
                    :class="{
                    blue: row.issue_status === '0',
                    yellow: row.issue_status === '1',
                    green: row.issue_status === '100',
                    gray: row.issue_status === '1000',
                  }">
                  {{ row.issue_status_name }}
                </div>
              </template>
            </vxe-column>
            <!--<vxe-column title="负责人" width="160px">-->
            <!--  <template v-slot="{row}">-->
            <!--    <div class="edp-owners">-->
            <!--      <div v-for="item in row.owners" :key="item.oid">-->
            <!--        <a-avatar :size="16" :src="item.pic" />-->
            <!--        <span>{{ item.title ? item.name + '-' + item.title : item.name }}</span>-->
            <!--      </div>-->
            <!--    </div>-->
            <!--  </template>-->
            <!--</vxe-column>-->
            <vxe-column title="协同人" width="170px" show-overflow>
              <template v-slot="{row}">
                <div class="edp-owners">
                  <div v-for="item in row.assists" :key="item.oid">
                    <a-avatar :size="16" :src="item.pic" style="margin-right: 5px;" />
                    <span>{{ item.title ? item.name + '-' + item.title : item.name }}</span>
                  </div>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="附件" width="110px">
              <template v-slot="{row}">{{ row.has_file === '1' ? '有' : '空'}}</template>
            </vxe-column>
            <vxe-column title="计划开始" field="schedule_start_date" width="110px"></vxe-column>
            <vxe-column title="实际开始" field="actual_start_date" width="110px"></vxe-column>
            <vxe-column title="计划结束" field="schedule_end_date" width="110px"></vxe-column>
            <vxe-column title="实际结束" field="actual_end_date" width="110px">
            </vxe-column>
            <vxe-column title="创建时间" field="create_time" width="110px">
            </vxe-column>
          </vxe-table>
        </div>
        <!--<task-issue-detail @reload="getList" v-if="(detailType === 'T' || detailType === 'M') && detailOid" :oid="detailOid"></task-issue-detail>-->
      </div>
      <a-pagination style="text-align: center;margin: 12px 0;" v-model="page.page_index"
                    :page-size="page.page_size" :total="page.total" showSizeChanger
                    :pageSizeOptions="['10', '15', '30', '50']" @change="getList" />
    </card>
    <relevancy-work-modal ref="relevancyWorkModal" @reload="reload" />
    <batch-change-modal @reload="getList" ref="batchChange"></batch-change-modal>
    <task-modal @reload="reload" ref="taskDetailModal">
      <template #IAccept>
        <div class="content-head-btn" v-if="['0', '10'].includes(selectList.issue_status)">
          <a-button type="primary" @click="IAccept" style="margin-left: 10px">
            我接受
          </a-button>
        </div>
      </template>
      <template #close>
        <div class="content-head-btn" style="margin-left: auto;margin-right: 20px;">
          <a-button type="primary" @click="deleteDetail" style="margin-left: 10px">
            删除
          </a-button>
        </div>
      </template>
      <template #detail>
        <div class="status-item">
          <img class="round" :src="$toUrl('right_detail_project.png')">
          <div class="status-item-right">
            <div style="padding-bottom: 3px; line-height: 1.2" class="status-title">{{ detail.project_name }}</div>
            <div class="status-sub-text">所属项目</div>
          </div>
        </div>
        <div class="status-item">
          <img class="round" :src="$toUrl('right_detail_project_code.png')">
          <div class="status-item-right">
            <div style="padding-bottom: 3px" class="status-title">{{ detail.project_code }}</div>
            <div class="status-sub-text">项目编号</div>
          </div>
        </div>
      </template>
    </task-modal>
    <!--    <create-demand-modal ref="modal2"></create-demand-modal>-->
    <!--    <create-task-modal @reload="getList" ref="modal1"></create-task-modal>-->
    <!--    <create-prj-modal @reload="getList" ref="createPrjModal"></create-prj-modal>-->
  </div>
</template>


<script>
import appsettings from "@/utils/appsettings";
import listMixin from "@/utils/mixins/listMixin.js";
import catMixin from "@/utils/mixins/catMixin.js";
import detailMixin from "@/utils/mixins/detailMixin.js";
import EdpInput from "@/components/e-form/edp-input.vue";
import { FieldUpdateDb } from "@/utils/FieldUpdate";
import BatchChangeModal from "@/components/batchChangeModal.vue";
import { fetch } from "@/utils/request";
import TaskModal from "@/components/taskModal.vue";
import RelevancyWorkModal from "@/components/relevancyWorkModal.vue";

export default {
  components: { RelevancyWorkModal, TaskModal, BatchChangeModal, EdpInput },
  mixins: [listMixin, catMixin, detailMixin],
  data() {
    return {
      isFilter: false,
      selectList: {},
      detail: {},
      treeData: [],
      dbs: {
        prjIssue: new FieldUpdateDb("prj", "prj_issue"),
      },
      searchOptions: {
        projectList: [],
        phaseList: [],
        accountList: []
      },
      listMixinOptions: {
        url: "/prj/issue/workitems",
      },
      detailOid: "",
      detailType: "",
      cat: {
        issue_bstatus: [],
        issues_level: [],
        issue_tags: []
      },
      listQuery: {
        keywords: "",
        orderby: "a.create_time desc",
        issue_status: this.$route.query.issue_status ?? "",
        prjoids: "",
        phase_oids: "",
        issue_owners: [],
        self_flag: "1",
        issue_level: '',
        issue_assists: [],
        issue_tags: [],
        schedule_start_date: [],
        schedule_end_date: [],
        actual_start_date: [],
        actual_end_date: [],
        create_time: [],
      },
      orderbyList: [
        { value: 'a.create_time asc', name: '创建时间正序' },
        { value: 'a.create_time desc', name: '创建时间倒序' },
        { value: 'a.issue_level asc', name: '优先级正序' },
        { value: 'a.issue_level desc', name: '优先级倒序' },
        { value: 'a.actual_start_date asc', name: '实际开始时间正序' },
        { value: 'a.actual_start_date desc', name: '实际开始时间倒序' },
        { value: 'a.schedule_start_date asc,a.schedule_end_date asc', name: '计划开始时间正序' },
        { value: 'a.schedule_start_date desc', name: '计划开始时间倒序' },
        { value: 'a.schedule_end_date asc', name: '计划结束时间正序' },
        { value: 'a.schedule_end_date desc', name: '计划结束时间倒序' },
        { value: 'a.issue_status asc', name: '状态升序' },
        { value: 'a.issue_status desc', name: '状态倒序' },
      ],
      selectedRowKeys: [],
      expandedRowKeys: [],
      appsettings: appsettings,
      maxDeep: 1,
    }
  },
  watch: {
    selectedRowKeys(val, pre) {
      const result = [...val]
      const findRow = (oid, list = this.list) => {
        const find = list.find(item => item.oid === oid)
        if (find) {
          return find
        } else {
          for (let item of list) {
            if (Array.isArray(item.children)) {
              let result = findRow(oid, item.children)
              if (result) {
                return result
              }
            }
          }
          return false
        }
      }
      let diff = val.filter(item => {
        return !pre.includes(item)
      }).map(item => findRow(item)).filter(item => item !== false)
      const deepSetChecked = (list) => {
        for (let item of list) {
          if (!result.includes(item.oid)) {
            result.push(item.oid)
          }
          if (Array.isArray(item.children)) {
            deepSetChecked(item.children)
          }
        }
      }
      deepSetChecked(diff)
      if (JSON.stringify(this.selectedRowKeys) !== JSON.stringify(result)) {
        this.selectedRowKeys = result
      }
    }
  },
  computed: {
    check() {
      let select = this.checkedList;
      return {
        all_indeterminate: select.length !== 0 && select.length !== this.list.length,
        all_checked: select.length === this.list.length
      }
    },
    checkedList() {
      return this.list.filter(item => {
        return this.selectedRowKeys.includes(item.oid)
      })
    },
  },
  created() {
    if (this.$route.query.oid && this.$route.query.detailType) {
      this.detailOid = this.$route.query.oid
      this.hasDetail = true
      this.detailType = this.$route.query.detailType
    }
    this.getProjectList()
    this.getAccountList()
    this.getTreeData()
  },
  methods: {
    toggleCheck(row) {
      let index = this.selectedRowKeys.indexOf(row.oid)
      console.log(index)
      if (index !== -1) {
        this.selectedRowKeys.splice(index, 1)
        this.$forceUpdate()
      } else {
        let result = []
        let deepSetChecked = (parent_oid) => {
          let filter = this.list.filter(item => {
            return item.parent_oid === parent_oid && !this.selectedRowKeys.includes(item.oid)
          })
          filter.forEach(item => {
            deepSetChecked(item.oid)
            result.push(item.oid)
          })
        }
        deepSetChecked(row.oid)
        this.selectedRowKeys.push(row.oid, ...result)
      }
    },
    toggleAllCheck() {
      if (this.check.all_indeterminate || !this.check.all_checked) {
        this.selectedRowKeys = this.list.map(item => item.oid)
      } else if (this.check.all_checked) {
        this.selectedRowKeys = []
      }
    },
    openRelevancyWorkModal(row) {
      this.$refs.relevancyWorkModal.prjoid = row.prjoid
      this.$refs.relevancyWorkModal.open(row)
    },
    // 获取项目阶段树形结构
    async getTreeData() {
      const res = await fetch('post', '/prj/phases')
      this.treeData = [
        {
          title: '全部',
          key: '0',
          oid: '0'
        }, ...res.data
      ]
      console.log('树形结构', res)
    },
    deleteDetail() {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/prj/issue/delete", { oid: this.detailOid }).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.$refs.taskDetailModal.visible = false
              this.search()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
    getDetail(oid) {
      return fetch("post", '/prj/issue/details', {
        oid
      }).then(res => {
        console.log('详情', res)
        this.detail = res.data
      })
    },
    IAccept() {
      fetch("post", "/prj/issue/accept", { oid: this.detailOid }).then(res => {
        if (res.status === 0) {
          this.$message.success("接受成功")
          this.$refs.taskDetailModal.getDetail()
          this.search()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    openTaskDetailModal(row) {
      this.detailOid = row.oid
      this.selectList = row
      this.getDetail(row.oid)
      this.$refs.taskDetailModal.open(row.oid)
    },
    reload() {
      this.getList()
    },
    selectTree(selectedKeys, e) {
      const isRoot = e.node.dataRef.key === '0'
      const isParent = e.node.dataRef.children
      this.listQuery.prjoids = isRoot || !isParent ? '' : e.node.dataRef.key
      this.listQuery.phase_oids = isRoot || isParent ? '' : e.node.dataRef.key
      this.search()
    },
    changeSelfFlag(e) {
      this.listQuery.self_flag = e.target.checked ? "0" : "1"
      this.search()
    },
    getListQuery(obj) {
      let query = {
        ...obj,
        issue_owners: obj.issue_owners.join(","),
        issue_assists: obj.issue_assists.join(","),
        issue_tags: obj.issue_tags.join(","),
      }
      if (query.schedule_start_date[0]) {
        query.schedule_start_date_begin = query.schedule_start_date[0]
        query.schedule_start_date_end = query.schedule_start_date[1]
      }
      delete query.schedule_start_date
      if (query.schedule_end_date[0]) {
        query.schedule_end_date_begin = query.schedule_end_date[0]
        query.schedule_end_date_end = query.schedule_end_date[1]
      }
      delete query.schedule_end_date
      if (query.actual_start_date[0]) {
        query.actual_start_date_begin = query.actual_start_date[0]
        query.actual_start_date_end = query.actual_start_date[1]
      }
      delete query.actual_start_date
      if (query.actual_end_date[0]) {
        query.actual_end_date_begin = query.actual_end_date[0]
        query.actual_end_date_end = query.actual_end_date[1]
      }
      delete query.actual_end_date
      if (query.create_time[0]) {
        query.create_time_begin = query.create_time[0]
        query.create_time_end = query.create_time[1]
      }
      delete query.create_time
      return query
    },
    getAccountList() {
      fetch("post", "/report/getAccount").then(res => {
        if (res.status === 0) {
          this.searchOptions.accountList = res.data
        }
      })
    },
    getProjectList() {
      fetch("post", "/prj/retrieve").then(res => {
        if (res.status === 0) {
          this.searchOptions.projectList = res.data.datas
        }
      })
    },
    getPhaseList() {
      fetch("post", "/prj/phase/retrieve", {
        page_size: 999,
        prjoid: this.listQuery.prjoids
      }).then(res => {
        if (res.status === 0) {
          this.searchOptions.phaseList = res.data.datas
        }
      })
    },
    onChangePrj() {
      this.listQuery.phase_oids = ""
      if (this.listQuery.prjoids) {
        this.getPhaseList()
      } else {
        this.searchOptions.phaseList = []
      }
      this.search()
    },
    openBatchChangeModal() {
      this.$refs.batchChange.open(this.selectedRowKeys.join(","))
    },
    async expand(expanded, record) {
      if (expanded && record.children.length === 0) {
        let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/retrieve/children?apifoxApiId=59781388" : "/prj/issue/retrieve/children"
        await fetch("post", url, {
          oid: record.oid
        }).then(res => {
          record.children = res.data
        })
      }
    },
    listFilter(list) {
      return list.map(item => {
        return {
          ...item,
          phase_name: item.phase_oid === "2300000001" ? "默认阶段" : item.phase_name,
          project_name: item.prjoid === "2300000001" ? "一般事项" : item.project_name,
        }
      }).map(item => {
        if (item.end_flag === "1") {
          return {
            ...item,
            children: []
          }
        }
        return item
      })
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            console.log(record.oid)
            this.detailOid = record.oid
            this.detailType = record.issue_type
          }
        }
      }
    },
    selectActionMenu({ key }) {
      alert("点击了key:" + key)
    },
    changeSelected(list) {
      console.log(list)
      this.selectedRowKeys = list
    }
  }
}
</script>

<style lang="less" scoped>
.filter-container {
  margin-bottom: 12px;

  .title {
    font-size: 18px;
    color: #000000;
    margin-bottom: 12px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 12px;

      .label {
        margin-right: 10px;
      }

      .ant-calendar-picker {
        width: 300px;
      }

      .ant-select {
        width: 180px;
      }
    }
  }
}

.filter {
  transition: all .3s;
  transform: rotate(-90deg);

  &.rotate {
    transform: rotate(90deg)
  }
}

.status-item {
  width: 130px;
  margin-right: 20px;
  display: flex;
  align-items: center;

  .round {
    //background-color: #D4D4D4;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .status-item-right {
    width: calc(100% - 41px);
    flex: 1;
    line-height: 1;
    margin-left: 11px;

    .status-title {
      font-size: 16px;
      color: #4D4D4D;
      margin-bottom: 4px;
      font-weight: 500;

      /deep/ .ant-select-selection-selected-value {
        font-size: 16px;
      }

      /deep/ .ant-select-search__field__wrap {
        input {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .status-sub-text {
      color: #A7A7A7;
      font-size: 12px;
    }
  }
}

/deep/ .edp-card {
  .edp-card-head-left-tabs {
    margin-left: -11px;
  }
}

/deep/ .ant-dropdown-button {
  > button {
    background-color: #0088FF;
    color: #FFF;
    border-color: #FFF;
  }

  margin-right: 10px;
}

.app-main-content {
  display: flex;

  /deep/ .vxe-table--render-default {
    .vxe-table--header-wrapper {
      background-color: #f8f8f8;

      .vxe-body--x-space {
        background-color: #f8f8f8;
      }

      .vxe-header--column {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .vxe-body--row {
      &.row--current {
        background-color: initial;
      }

      &.row--hover {
        background-color: #fff4e6;
      }

      .vxe-tree--btn-wrapper {
        display: flex;
      }
    }
  }

  > .left {
    width: 230px;
    margin-right: 10px;

    /deep/ .edp-card-body {
      padding-left: 10px;

      .ant-tree {
        .ant-tree-switcher_close {
          .icon-xiangxia {
            transform: rotate(-90deg)
          }
        }

        .icon-xiangxia {
          transition: all .3s;
        }
      }
    }
  }

  /deep/ .page-card-body {
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    // 表格上方的操作栏
    > .body-action {
      display: flex;
      border-bottom: 1px solid #eee;

      .left {
        > span {
          margin-left: 11px;
          font-size: 16px;
        }

        .btn {
          font-size: 14px;
        }

      }

      .right {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-bottom: 20px;

        .item {
          margin-left: 20px;

          .ant-select-selection__rendered {
            line-height: 32px;
          }

          .label {
            margin-right: 10px;
          }
        }
      }
    }

    .condition {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      span {
        font-size: 16px;
        color: #4D4D4D;
        font-weight: 400;
      }

      .condition_input {
        width: 172px;

        height: 34px;
        margin: 0 15px;
      }

      .condition_clear {
        font-size: 16px;
        color: #0088FF;
      }

    }

    // 表格和右侧信息的父元素 选择器
    > .body-flex {
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;

      // 左侧表格
      > .body-left-table {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        background: #FFF;
        flex-shrink: 0;

        .icon {
          width: 16px;
          height: 16px;
        }
      }

      // 右侧信息
      > .body-right-info {
        flex-shrink: 0;
        padding: 10px 0 0 0;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        border-top: 1px solid #ededed;
        background-color: #FFF;

        // 可以滚动的部分
        .info-scroll-content {
          padding-right: 20px;
          overflow-y: auto;
          padding-bottom: 10px;
          padding-left: 20px;

          // 头部
          .content-head {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .anticon {
              margin-right: 5px;
              font-size: 16px;
            }

            .content-head-id {
              display: flex;
              align-items: center;

              .anticon {
                color: #36A3FF;
              }

              > img {
                display: inline-block;
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 8px;
              }

              .id {
                color: #4D4D4D;
                font-size: 12px;
              }
            }

            .content-head-icons {
              margin-left: auto;
            }
          }

          // 标题
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            color: #4D4D4D;
          }

          // 两列的状态
          .scroll-content-status {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 24px;
            margin-top: 13px;
            margin-bottom: 35px;

            .status-item {
              display: flex;
              align-items: center;

              .round {
                //background-color: #D4D4D4;
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }

              .status-item-right {
                line-height: 1;
                margin-left: 11px;

                .status-title {
                  font-size: 16px;
                  color: #4D4D4D;
                  margin-bottom: 4px;
                }

                .status-sub-text {
                  color: #A7A7A7;
                  font-size: 12px;
                }
              }
            }
          }

          // 描述输入框 后期会换成富文本编辑框
          .description {
            width: 100%;
            height: 150px;
            margin-top: 15px;
            //border: 1px solid #EDEDED;
          }

          // 文本信息展示
          .content-group-info {
            padding-top: 20px;

            // 每组信息的标题
            .group-info-title {
              margin-top: 34px;
              font-size: 18px;
              color: #4D4D4D;
              margin-bottom: 15px;
            }

            .taps {
              margin-left: 10px;
              display: inline-block;
              font-size: 12px;
              color: #A7A7A7;
              border: 1px solid #EDEDED;
              line-height: 26px;
              padding: 0 14px;
              border-radius: 26px;
            }

            // 每组信息列表
            .group-info-list {
              .info-item {
                display: flex;
                align-items: center;
                color: #4D4D4D;
                font-size: 16px;

                &:not(:last-child) {
                  margin-bottom: 15px;
                }

                .info-key {
                  width: 150px;
                }
              }

              .work-list {
                max-height: 150px;
                overflow-y: auto;
                margin-bottom: 20px;

                .work-item {
                  display: flex;
                  line-height: 36px;
                  justify-content: space-between;
                  border-bottom: 1px solid #EDEDED;;
                  position: relative;

                  .edp-status {
                    font-size: 12px;
                  }

                  .text-block {
                    margin-right: 10px;
                    display: inline-block;
                    font-size: 12px;
                    color: #4D4D4D;

                    &.red {
                      color: #ED3535;
                      background-color: #FDE6E6;
                    }

                    &.blue {
                      color: var(--primary-color);
                      background-color: #E0ECFB;
                    }
                  }

                  .icon {
                    display: inline-block;
                    margin-right: 10px;
                    width: 16px;
                  }

                  &:first-child {
                  }

                }
              }

              //动态和评论
              .container-tabs {
                height: 100%;

                .ant-tabs-nav .ant-tabs-tab {
                  margin: 0;
                }

                .ant-tabs-bar {
                  margin: 0;
                }

                .ant-tabs-content {
                  height: 100%;
                }

                .tabs-content {
                  height: 100%;

                  .dynamic-items {
                    padding: 13px;
                    align-items: baseline;

                    .info {
                      padding-left: 14px;
                      font-size: 12px;
                      color: #909090;

                      .name {
                        color: #000000;
                        margin-bottom: 9px;

                        > span {
                          color: #909090;
                        }
                      }

                      .detail {
                        .icon {
                          transform: rotate(-90deg);
                          color: #909090;
                        }

                        > div {
                          margin-right: 7px;
                        }

                        > div:nth-last-child(1) {
                          margin: 0;
                        }

                        .finish {
                          color: #24B47E;
                          background-color: #E9F7F2;
                        }

                        .unfinish {
                          color: #EA0D0D;
                          background: #FDE6E6;
                        }
                      }
                    }
                  }

                  .detail-title {
                    padding-left: 16px;
                    height: 55px;
                    line-height: 55px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #CACACA;
                    font-size: 14px;
                    color: #000000;
                  }

                  .detail-items {
                    padding: 13px 41px;

                    .detail-item-1 {
                      margin-bottom: 14px;

                      .top {
                        color: rgba(0, 0, 0, 1);
                        font-size: 12px;
                        margin-bottom: 9px;

                        > div {
                          margin-right: 5px;
                        }

                        > div:nth-last-child(1) {
                          margin-right: 0;
                          color: rgba(144, 144, 144, 1);
                        }

                        .status {
                          color: #24B47E;
                          background: #E9F7F2;
                          padding: 0 5px;
                        }

                        .status.nopass {
                          color: #EA0D0D;
                          background: #FDE6E6;
                        }
                      }

                      .bottom {
                        font-size: 12px;
                        color: #909090;
                        font-weight: 500;
                        align-items: baseline;
                        height: 100%;
                        position: relative;

                        > div {
                          margin-left: 14px;
                        }

                        > div:nth-last-child(1) {
                          width: 205px;
                          color: var(--primary-color);
                          font-weight: 500;
                        }

                        > div:nth-of-type(1) {
                          margin: 0;
                        }
                      }
                    }

                    .detail-item-2 {
                      font-size: 12px;
                      color: #000000;
                      margin-bottom: 14px;

                      > button {
                        margin-right: 13px;
                      }

                      > button:nth-last-child(1) {
                        border: 1px solid var(--primary-color);
                        color: var(--primary-color);
                      }
                    }
                  }

                  .comment-items {
                    padding: 13px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #000000;

                    > img {
                      width: 19px;
                      height: 19px;
                      vertical-align: middle;
                    }

                    .info {
                      margin: 0 8px;

                      > .name {
                      }

                      > .time {
                        font-weight: 400;
                        font-size: 12px;
                        color: #919191;
                      }
                    }

                    .content {
                      font-weight: 400;
                      color: #919191;
                    }
                  }
                }
              }
            }
          }
        }

        .filecontent {
          margin-bottom: 20px;

          .item {
            margin-bottom: 15px;
            border-bottom: 1px solid #C2C9CF;
            padding-bottom: 15px;

            .left {
              width: 100%;

              .file-icon {
                width: 32px;
                height: 32px;
                margin-right: 5px;

                > img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .file-info {
                flex-grow: 1;
                font-weight: 500;
                font-size: 12px;
                color: #9B9B9B;

                .file-info-name {
                  color: #303030;
                  margin-bottom: 3px;
                }

                .file-info-size {
                  margin-right: 25px;
                }
              }
            }

            .right {
              flex-shrink: 0;

              > .file-operation {
                cursor: pointer;
                margin-left: 11px;

                > i {
                  font-size: 14px;
                  color: #303030;
                  font-weight: 700;
                }
              }

              > .file-operation:nth-of-type(1) {
                margin-left: 0;
              }
            }
          }

          & > .item:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

        // 右侧下方固定不滚动的部分
        .right-info-footer {
          border-top: 1px solid #eee;
          flex-shrink: 0;
          height: 96px;

          // 评论部分
          .input-group {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EDEDED;

            .input {
              height: 48px;
              border: none;
              outline: none;
              flex: 1;
              padding-left: 10px;
            }

            .btn {
              cursor: pointer;
              width: 100px;
              text-align: center;

              .anticon {
                font-size: 20px;
                color: #333;
              }
            }
          }

          // 关注部分
          .follow {
            display: flex;
            height: 48px;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;

            .text {
              color: #4D4D4D;
              font-size: 16px;

              // 关注人数
              span {
                line-height: 16px;
                height: 16px;
                display: inline-block;
                background-color: #D9D9D9;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                border-radius: 4px;
                padding: 0 5px;
                margin: 0 5px;
              }
            }

            // 关注按钮
            .action {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 0 20px;

              .anticon {
                margin-right: 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

/deep/ .ant-table-body {
  .ant-table-tbody {
    tr {
      td {
        white-space: nowrap;
      }
    }
  }
}
</style>
